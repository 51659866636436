#root{
  background-color: var(--main-bg-color);
}
.App {
  text-align: center;
  /*min-width: 80vh;*/
  background-color: var(--main-bg-color);
  /*width: 70%;*/
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media screen and (max-width: 800px) {
  .align-left-mobile-only {
    text-align: left;
  }
  .banner-small {
    display: block!important;
  }
  .banner-big {
    display: none!important;
  }

  .action-div {
    width: 85%!important
  }

  .align-items-flex-start {
    align-items: flex-start!important
  }

}
@media screen and (max-width: 1240px) {
  .gradient-wave-bg {
    min-height: 120px!important;
    margin-top: -5px!important;
  }
  .gradient-wave-bg-orange {
    min-height: 120px!important;
    margin-top: -5px!important;
  }

  .column-33 {
    flex-basis: 100%!important; /* Each column takes up full width */
    /*background-color: var(--main-bg-color);*/
  }
  .half-width {
    width: 50%;
  }

  .action-menu-small {
    display: inline-flex!important;
    padding: 15px 0px 15px 0px;
  }
  .middle {
    display: none!important;
    height: 0px!important;
    margin: 0px 10px;
  }
  .rotate90 {
    /*transform: rotate(90deg);*/
    display: none!important;
    height: 0px!important;
  }
  .emptySpace30 {
    display: none;
  }
  .emptySpace35 {
    display: none;
  }

  .contact-menu {
    width: 35px;
    height: 41px;
  }

  .profile-menu {
    display: inline-flex;
    width: 49px;
    height: 40px;
    cursor: pointer;
  }

  .App {
    width: 100%;
  }

  .flexing {
    flex-direction: column!important;
  }
  .flexing-reverse {
    flex-direction: column!important;;
  }
  .stop-margin {
    margin-top: 0px!important;
    margin-bottom: 10px!important;
  }
  .image-container {
    display: none!important;
    height: 0px!important;
  }
  .column-50 {
    align-items: center!important;
  }
  .section-inner-header {
    font-family: var(--secondary-font-family);
    font-size: 30px!important;
    font-weight: bold;
  }
  .action-menu-big {
    display: none!important;
  }

  .small-container {
    width: 85%!important
  }

  .big-container {
    width: 90%!important
  }

  .brushstroke-cefacem {
    width: 65%!important;
  }

  .brushstroke-cumfacem {
    width: 90%!important;
  }

  .brushstroke-decefacem {
    width: 65%!important;
  }

  .brushstroke-aventura {
    width: 75%!important;
  }

  .brushstroke-faq {
    width: 90%!important;
  }

  .faq-div {
    width: 85%!important;
  }

  .App-body {
    width: 95%!important;
  }

  .new-line {
    display: none!important;
  }

  img.bookInPlan {
    max-height: 170px!important;
    max-width: 170px!important;
    object-fit: cover!important;
  }

  .plan-dot{
    height: 30px!important;
    width: 30px!important;
  }
}

@media screen and (max-width: 800px) {
  .gradient-wave-bg {
    min-height: 55px !important;
    margin-top: -5px !important;
  }
  .gradient-wave-bg-orange {
    min-height: 55px !important;
    margin-top: -10px !important;
  }
}

@media screen and (max-width: 1773px) {
  .App-body {
    width: 90% !important;
  }
}

.fauna-one-regular {
  font-weight: 400;
  font-style: normal;
  color: #404e65 ;
}

.book-age-group {
  font-size: 21px;
  color: var(--aventura-dark-color);
  margin-bottom: 10px;
  font-weight: bold;
}

.fauna-one-bold {
  font-weight: 800;
  font-style: normal;
  color: #404e65 ;
}

.paytone-one-regular {
  font-weight: 400;
  font-style: normal;
}

body {
  color: #404e65 ;
}

footer {
  color: #667085;
  padding-bottom: 2rem;
}
.app-header-div {
  width: 70%;
}

.App-header {
  width: 100%;
  margin: auto;
  /*min-height: 12vh;*/
  /*max-height: 12vh;*/
  flex-direction: column;
  font-size: calc(10px + 2vmin);
  color: white;
  /*padding-bottom: 2%;*/

  z-index: 1001;
  /*padding-right: var(--padding--horizontal);*/
  /*padding-left: var(--padding--horizontal);*/
  -webkit-backdrop-filter: blur(8px);
  backdrop-filter: blur(8px);
  border-bottom: 1px solid #f2f4f7;
  justify-content: center;
  align-items: center;
  display: flex;
  position: sticky;
  top: 0;
  background-color: var(--menu-background-color);
}

.action-section {
  display: flex;
  justify-content: center;
}

.action-section-in {
    /*display: flex;*/
    /*flex-direction: column;*/
    align-items: center;
    /*justify-content: normal;*/
    font-size: calc(10px + 2vmin);
    color: white;
    padding-bottom: 2%;
    width: 70%;
    margin: auto;
}

.action-div {
  width: 70%!important
}

:root {
  --color--text-primary: #323232;
  --padding--horizontal: 20rem;
  --container--standard: 1216rem;
  --white: white;
  --padding--desktop: 96rem;
  --padding--tablet: 64rem;
  --color--grey-background: white;
  --black: black;
  --container--large: 1216rem;
  --color--yellow: rgba(255, 196, 45, .31);
  --color--beige: white;
  --color: white;

  --main-bg-color: #FFFFFF;
  --button-bg-color: #52027E;
  --button-color: #FFFFFF;
  --button-hover-text: #FFFFFF;
  --button-hover-background: #cf2e2e;
  --red-text: #d62828;

  --orange: #F38f1a;
  --yellow: #FFe006;
  --red: #e41f1a;
  --blue: #23bcef;
  --green: #95ce19;

  --cefacem-color: #CDB4DB;
  --cefacem-color-light: #CDB4DB30;
  --cefacem-dark-color: #52027E; /* ! used on logo ! */
  /*--cumfacem-color: #F2C166;*/
  /*--cumfacem-dark-color: #AB6700;*/
  /*--cumfacem-color: #fda75e;*/
  /*--cumfacem-color: #fb8b64;*/
  /*--cumfacem-dark-color: #B42E00; !* #FFDAB9 or #FFDABE. *!*/
  /*--decefacem-color: #7fc296;*/
  --cumfacem-color: #FFC357;
  --cumfacem-dark-color: #AB6700;
  --decefacem-color: #70d3c4;
  --decefacem-dark-color: #0D5256;
  --aventura-color: #CDB4DB;
  --aventura-dark-color: #52027E;
  --faq-color: #e3e5e9;
  --faq-dark-color: #667085;
  --menu-background-color: #CDB4DB;
  --menu-background-color-faded: #CDB4DB80;
  --grey-color: #667085;

  /*--primary-font-family: "Fauna One", sans-serif;*/
  --primary-font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  /*--primary-font-family: "Poppins", sans-serif;*/
  --secondary-font-family: "Paytone One", sans-serif;
}

.App-body {
  background-color: var(--main-bg-color);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: normal;
  color: white;
  padding-bottom: 2%;
  width: 70%;
  margin: auto;
}

.App-footer {
  background-color: var(--main-bg-color);
  min-height: 8vh;
  /*max-height: 8vh;*/
  display: flex;
  /*flex-direction: column;*/
  /*align-items: center;*/
  /*justify-content: center;*/
  font-size: calc(10px + 1vmin);
  flex-wrap: wrap;
}

.App-link {
  color: #61dafb;
}

.parent {
  display: flex; /* Use flexbox layout */
  width: 100%;
  /*max-width: 80%;*/
  flex-wrap: wrap;

  justify-content: center; /* Center content horizontally */
  align-items: center; /* Center content vertically */
}

.faq-div {
  align-items: flex-start;
  justify-content: center;

  width: 60%;
  margin: auto;
  padding-bottom: 2%;
  font-size: calc(10px + 1.5vmin);
}

.column-33 {
  flex: 1; /* Each column takes up equal space */
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px; /* Add padding for spacing */
  /*border: 1px solid #ccc; !* Add border for visualization *!*/
  box-sizing: border-box;
}
.big-cover-illustration {
  width: 300px!important;
  height: 260px!important;
}
.small-cover-illustration {
  margin-top: 20px;
  width: 200px!important;
  height: 175px!important;
}
.cover-align-start {
  justify-content: center;
  align-items: flex-start;
}
.cover-align-end {
  justify-content: center;
  align-items: flex-end;
}
.middle {
  display:flex;
  align-items: self-end;
  width: 100%;
  height: 150px;
}
.column-50 {
  flex: 1; /* Each column takes up equal space */
  display: flex;
  flex-direction: column;

  padding: 10px; /* Add padding for spacing */
  /*border: 1px solid #ccc; !* Add border for visualization *!*/
  box-sizing: border-box;
}

.column-50-no-equal {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px; /* Add padding for spacing */
  /*border: 1px solid #ccc; !* Add border for visualization *!*/
  box-sizing: border-box;
}

.align-start {
  align-items: self-start!important;
}
.align-end {
  align-items: self-end!important;
}

.column img {
  width: 100%; /* Make the SVG expand to fill the column width */
  height: auto; /* Maintain aspect ratio */
}

.profile-menu {
  display: inline-flex;
  width: 39px;
  height: 30px;
  cursor: pointer;
}

.span-bold-text {
  font-weight: 600;
}

.grey-text {
  color: #404e65;
}


.span-minor-text {
  color: #404e65 ;
  font-size: 18px;
  padding: 20px;
  font-family: "Poppins", sans-serif;
  line-height: 28px;
  font-style: normal;
}

.span-minor-text-without-padding {
  color: #404e65 ;
  font-size: 18px;
  font-family: "Poppins", sans-serif;
  line-height: 28px;
  font-style: normal;
}

.white-and-bold-text {
  color: var(--button-hover-text);
  font-weight: 600;
}

.tag-container-text {
  color: #121212 ;
  font-size: 25px;
}

.tag-container-details {
  color: rgba(18, 18, 18, 0.75)!important;
  font-size: 18px;
}

.underline{
  text-decoration: underline!important;
}

.contact-menu {
  display: inline-flex;
  width: 49px;
  height: 53px;
  cursor: pointer;
}

.button-selected {
  color: #6C00A9;
  /*border-bottom: 1px solid #6C00A9*/
}

.button-just-text {
  display: inline-block; /* Use inline-flex to adjust width based on content */
  padding: 8px 16px;
  font-size: 18px;
  font-weight: 600;
  text-decoration: none;
  text-align: center;
  cursor: pointer;
  border: 0px;
  color: var(--button-color);
  background-color: transparent;
  transition: background-color 0.3s, color 0.3s, border-color 0.3s;
  font-family: "Poppins", sans-serif;
  --bs-btn-color: transparent;
  --bs-btn-bg: transparent;
  --bs-btn-border-color: transparent;
  --bs-btn-hover-color: transparent;
  --bs-btn-hover-bg: transparent;
  --bs-btn-hover-border-color: transparent;
  --bs-btn-active-color: transparent;
  --bs-btn-active-bg: transparent;
  --bs-btn-active-border-color: transparent;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: transparent;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: transparent;
}

.button-just-text:hover {
  color: #6C00A9;
}

.button-just-text2 {
  display: inline-block; /* Use inline-flex to adjust width based on content */
  /*padding: 8px 16px;*/
  font-size: 1.5vh;
  font-weight: normal;
  text-decoration: none;
  text-align: center;
  cursor: pointer;
  border: 0px;
  color: #333;
  background-color: transparent;
  transition: background-color 0.3s, color 0.3s, border-color 0.3s;
  --bs-btn-color: transparent;
  --bs-btn-bg: transparent;
  --bs-btn-border-color: transparent;
  --bs-btn-hover-color: #6C00A9;
  --bs-btn-hover-bg: transparent;
  --bs-btn-hover-border-color: transparent;
  --bs-btn-active-color: transparent;
  --bs-btn-active-bg: transparent;
  --bs-btn-active-border-color: transparent;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: transparent;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: transparent;
}
.button-just-text3.active {
  text-decoration: underline;
}
.button-just-text3:hover {
  text-decoration: underline;
}

.button-just-text3.white{
  color: #FFFFFF;
}
.button-just-text3 {
  display: inline-block; /* Use inline-flex to adjust width based on content */
  padding: 10px 10px;
  font-weight: normal;
  text-decoration: none;
  text-align: center;
  cursor: pointer;
  border: solid;
  color: #333;
  background-color: transparent;
  transition: background-color 0.3s, color 0.3s, border-color 0.3s;

  --bs-btn-color: transparent;
  --bs-btn-bg: transparent;
  --bs-btn-border-color: #6C00A9;
  --bs-btn-hover-color: transparent;
  --bs-btn-hover-bg: transparent;
  --bs-btn-hover-border-color: #6C00A9;
  --bs-btn-active-color: #6C00A9;
  --bs-btn-active-bg: transparent;
  --bs-btn-active-border-color: #6C00A9;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #666666;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: transparent;
}
.button-just-text3:hover {
  color: #6C00A9;
}


.button-contact {
  padding: 8px 16px;
  font-size: 18px;
  font-weight: 600;
  text-decoration: none;
  text-align: center;
  cursor: pointer;
  border: 1px solid var(--button-bg-color);
  color: var(--button-color);
  background-color: var(--button-bg-color);
  box-shadow: none;
  border-radius: 8px;
  font-family: "Poppins", sans-serif;
  display: inline-flex;

  --bs-btn-hover-color: var(--button-bg-color);
  --bs-btn-hover-bg: var(--button-bg-color);
  --bs-btn-hover-border-color: var(--button-bg-color);

  /*letter-spacing: 0px;*/
  /*box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);*/
  /*border-radius: 20px;*/
  /*border-radius: 20px;*/
  /*width: 70%;*/
  /*width: 50%;*/
}
.small-action-button {
  cursor: pointer;
  border: 1px solid var(--button-bg-color);
  color: var(--button-color);
  background-color: var(--button-bg-color);
  box-shadow: none;
  font-family: "Poppins", sans-serif;
  display: inline-flex;

  --bs-btn-hover-color: var(--button-bg-color);
  --bs-btn-hover-bg: var(--button-bg-color);
  --bs-btn-hover-border-color: var(--button-bg-color);
}

.price {
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 14px;
  align-items: center;
}
.price-big {
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 20px;
  padding-right: 15px;
}

.add-to-bag {
  font-size: 18px;
  font-weight: 600;
  text-decoration: none;
  text-align: center;
  cursor: pointer;
  border: 1px solid var(--cefacem-dark-color);
  color: var(--button-color);
  background-color: var(--cefacem-dark-color);
  box-shadow: none;
  font-family: "Poppins", sans-serif;
  display: inline-flex;
  border-radius: 40px;
  --bs-btn-active-color: var(--cefacem-color);
  --bs-btn-active-bg: var(--cefacem-color);
  --bs-btn-active-border-color: var(--cefacem-color);
  --bs-btn-hover-bg: var(--cefacem-color);
  --bs-btn-hover-border-color: var(--cefacem-color);
}
.navigate-back-button {
  font-size: 18px;
  font-weight: 600;
  text-decoration: none;
  text-align: center;
  cursor: pointer;
  border: 1px solid rgba(var(--cefacem-color), 0.5);
  color: var(--button-color);
  background-color: rgba(205, 180, 219, 0.5);
  --bs-btn-active-color: var(--button-bg-color);
  --bs-btn-active-bg: var(--button-bg-color);
  --bs-btn-active-border-color: var(--button-bg-color);
  --bs-btn-hover-color: var(--button-bg-color);
  --bs-btn-hover-bg: var(--button-bg-color);
  --bs-btn-hover-border-color: var(--button-bg-color);

  box-shadow: none;
  font-family: "Poppins", sans-serif;
  display: inline-flex;
  border-radius: 40px;
}

.discover-more {
  text-align: center;
  background-color: var(--cefacem-color);
  margin-left: 16px;
  padding-left: 16px;
  padding-right: 16px;
  min-height: 200px;
  max-width: 180px;

  filter: drop-shadow(rgba(205, 180, 219, 0.5) 20px 30px 10px);

  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--aventura-dark-color);
  font-size: 12px;
  font-weight: 100;
}

.book-group {
  color: var(--aventura-dark-color);
  margin-bottom: 10px;
  font-weight: bold;
  padding-bottom: 10px;
  /*border: 1px solid var(--cefacem-color);*/
  /*box-shadow: 0 0 5px var(--cefacem-color);*/

  width: 100%;
  transition: transform 1.5s ease-in-out;
}

.book-group-header {
  font-size: 21px;
  margin-bottom: 10px;
  padding-bottom: 35px !important;
}

.add-to-bag-dark {
  font-size: 18px;
  font-weight: 600;
  text-decoration: none;
  text-align: center;
  cursor: pointer;
  border: 1px solid var(--button-bg-color);
  color: var(--button-color);
  background-color: var(--button-bg-color);
  box-shadow: none;
  font-family: "Poppins", sans-serif;
  display: inline-flex;
  border-radius: 40px;
}

.button-contact-orange {
  border: 1px solid var(--cumfacem-color);
  background-color: var(--cumfacem-color);
}
.orange-background {
  background-color: #FFE1AB;
}

.button-contact:hover {
  background-color: var(--button-hover-background);
  color: var(--button-hover-text);
  border: 1px solid var(--button-hover-background);
}

.brushstroke {
  --brush-color: var(--cefacem-color);
  background-image: paint(brushstroke);
}

.brushstroke-cefacem {
  --brush-color: var(--cefacem-color);
  background-image: paint(brushstroke);
  color: var(--cefacem-dark-color);
  width: 25%;
}

.brushstroke-cumfacem {
  --brush-color: var(--cumfacem-color);
  background-image: paint(brushstroke);
  color: var(--cumfacem-dark-color);
  width: 35%;
}

.brushstroke-decefacem {
  --brush-color: var(--decefacem-color);
  background-image: paint(brushstroke);
  color: var(--decefacem-dark-color);
  width: 35%;
}

.brushstroke-dece-detalii {
  --brush-color: var(--decefacem-color);
  background-image: paint(brushstroke);
  font-size: 20px!important;
  color: #404e65 ;
  margin: 30px;
  /*width: 20%;*/
}

.brushstroke-faq {
  --brush-color: var(--faq-color);
  background-image: paint(brushstroke);
  color: var(--faq-dark-color);
  width: 40%;
}

.brushstroke-aventura {
  --brush-color: var(--aventura-color);
  background-image: paint(brushstroke);
  color: var(--aventura-dark-color);
  width: 40%;
}

.flexing {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  /*flex-direction: column;*/
  margin: 0 10px;
}

.terms {
  align-items: flex-start;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  text-align: justify;
}

.book-details {
  align-items: flex-start;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  text-align: left;
}

.flexing-reverse {
  margin: 0 10px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  flex-direction: row-reverse;
  justify-content: center;
}

.border {
  background-color: var(--main-bg-color);
  border-radius: 8px;
  padding: 22px 22px 22px 22px;
  min-height: 100px !important;
  box-shadow: 0 8px 18px 0 rgba(0, 0, 0, 0.15);
}
.voucher {
  background-color: var(--main-bg-color);
  border-radius: 8px;
  padding: 22px 22px 22px 22px;
  box-shadow: 0 8px 18px 0 rgba(0, 0, 0, 0.15);
  flex-direction: column;
  display: flex;
  color: black;
  text-align: left;
  margin-top: 10px;
}

.section-header {
  font-size:50px;
  font-weight:400;
  position:absolute;
  zIndex:1;
  width:100%;
  top:13%;
  left:2%
}

.deep-purple {
  color: #52027E;
}
.deep-pink {
  color: #B24770;
}
.deep-blue {
  color: #282363;
}

.low-highlight {
  background:linear-gradient(180deg, transparent 60%, var(--aventura-color) 60%);
}

.low-highlight-purple {
  background:linear-gradient(180deg, transparent 60%, var(--button-bg-color) 120%);
  font-weight: bold;
}
.low-highlight-orange {
  background:linear-gradient(180deg, transparent 60%, var(--cumfacem-dark-color) 120%);
  font-weight: bold;
}

.dot-big-purple {
  /* Ellipse 2 */

  position: absolute;
  width: 242px;
  height: 236px;
  left: 20%;
  top: 2%;
  border-radius: 50%;
  background: var(--cefacem-color);
}

.subscription-dot-big-purple {
  position: absolute;
  width: 242px;
  height: 236px;
  left: 10%;
  top: 3%;
  border-radius: 50%;
  background: var(--cefacem-color);
}

.dot-small-pink {
  /* Ellipse 2 */
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 74px;
  height: 71px;
  left: calc(50% - 37px);
  top: 5%;
  border-radius: 50%;
  background: var(--cumfacem-color);;
  font-size: 20px;
  color: #404e65 ;
  padding-bottom: 1%;
}
/* Ellipse 5 */

.emptySpace35 {
  width: 35%;
}

.emptySpace10 {
  width: 10%;
}

.emptySpace30 {
  width: 30%;
  overflow: visible;
  z-index: 6;
  height: 300px;
}

.container {
  position: relative; /* Ensure the container acts as the positioning context for the absolutely positioned image */
  display: inline-flex; /* Ensure the container doesn't take full width */
  margin: 60px;
}

.background-image {
  position: absolute; /* Position the image absolutely within the container */
  top: 50%;
  left: 7%;
  z-index: 1; /* Send the image to the back */
}

.text {
  position: relative; /* Ensure the text is positioned relative to the container */
  z-index: 2; /* Bring the text to the front */
  margin: 0; /* Remove any default margin */
  padding: 20px; /* Optional: Add padding for better visibility */
}

.small-container {
  width: 70%
}

.big-container {
  width: 70%
}


/* Rectangle 18 */
.faq-collapsed {
background: var(--decefacem-color);
box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
border-radius: 18px;
border-color: transparent;
font-weight: 400;
font-style: normal;
font-size: 24px;
cursor: pointer;
}

.arrow-up {
  transform: rotate(180deg);
}

.toggle-open {
  display: flex;
}

.toggle-closed {
  display: none;
}

.section-inner-header {
  display:inline-flex;
  justify-content:center;
  font-family: "Poppins", sans-serif;
  font-size: -webkit-xxx-large;
  font-weight: bold;
}

.action-menu-small {
  display: none;
}

.banner-small {
  display: none;
}
.carteA {
  width: 80%;
}

.banner {
  margin-bottom: 20px;
}
.banner-image {
  max-height: 700px;
  min-height: 320px;
}

.carousel .control-dots .dot {
  box-shadow: none!important;
}

.carousel .control-dots .dot {
  background: #ccb4da!important;
}

.info-banner-container {
  /*gap: 1rem;*/
  display: flex;
  flex-wrap: wrap;
}

.book-topic-container {
  display: flex;
  flex-wrap: wrap;
}

.info-banner-column {
  display: flex;
  flex-direction: column;
  text-align: center;
  gap: 0.5rem;
  padding: 10px;
}

.align-left {
  text-align: left;
}

.from-error {
  width: 100%;
  margin-top: .25rem;
  font-size: .875em;
  color: red;
}

.login-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: normal;
  font-size: calc(10px + 2vmin);
  color: white;
  padding-bottom: 2%;
  width: 70%;
  margin: auto;
}
.div-with-background {
  background-color: var(--menu-background-color)!important;
}

.gradient-wave-bg {
  min-height:220px;
  margin-top:-5px;

  background-size:100%;
  background-repeat: no-repeat;
  background-image: url("data:image/svg+xml, %3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 41 500 62'%3E%3Cpath fill='%23CDB4DB' d='M 0 50 C 150 150 300 0 500 80 L 500 0 L 0 0'/%3E%3Cpath opacity='0.8' fill='%23CDB4DB' d='M 0 50 C 150 150 330 -30 500 50 L 500 0 L 0 0'/%3E%3Cpath opacity='0.5' fill='%23CDB4DB' d='M 0 50 C 215 150 250 0 500 100 L 500 0 L 0 0'/%3E%3C/svg%3E");
  /*-webkit-transform: scaleX(-1);*/
  /*transform: scaleX(-1);*/
}
.gradient-wave-bg-orange {
  min-height:220px;
  margin-top:-5px;

  background-size:100%;
  background-repeat: no-repeat;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 41 500 62'%3E%3Cpath fill='%23FFE1AB' d='M 0 50 C 150 150 300 0 500 80 L 500 0 L 0 0'/%3E%3Cpath opacity='0.8' fill='%23FFE1AB' d='M 0 50 C 150 150 330 -30 500 50 L 500 0 L 0 0'/%3E%3Cpath opacity='0.5' fill='%23FFE1AB' d='M 0 50 C 215 150 250 0 500 100 L 500 0 L 0 0'/%3E%3C/svg%3E");
}

.flip {
  transform: rotate(180deg);
  /*background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 500 62' height='62' width='500'%3E%3Cg xmlns='http://www.w3.org/2000/svg' transform='matrix(-1 0 0 -1 500 103)'%3E%3Cpath fill='%23CDB4DB' d='M 0 50 C 150 150 300 0 500 80 L 500 0 L 0 0'/%3E%3Cpath opacity='0.8' fill='%23CDB4DB' d='M 0 50 C 150 150 330 -30 500 50 L 500 0 L 0 0'/%3E%3Cpath opacity='0.5' fill='%23CDB4DB' d='M 0 50 C 215 150 250 0 500 100 L 500 0 L 0 0'/%3E%3C/g%3E%3C/svg%3E")!important;*/
}

.rotate {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}

.top-section {
  padding: 15px;
  color: var(--button-bg-color);
  line-height: 28px;
}

.top-section-xmas {
  padding: 15px 40px;
  color: var(--button-bg-color);
  line-height: 28px;
}

.info-banner-value-colored {
  border-radius: 9999px;
  -webkit-padding-start: 1rem;
  padding-inline-start: 1rem;
  -webkit-padding-end: 1rem;
  padding-inline-end: 1rem;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  font-weight: 600;
  background: var(--cefacem-color-light);
  font-size: 14px;
}

.info-banner-value-activity {
margin-top: 20px;
  border-radius: 9px;
  -webkit-padding-start: 1rem;
  padding-inline-start: 1rem;
  -webkit-padding-end: 1rem;
  padding-inline-end: 1rem;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  font-weight: 600;
  background: var(--cefacem-color-light);
  font-size: 14px;
}

.info-banner-value-colored-section {
  border-radius: 9999px;
  -webkit-padding-start: 1rem;
  padding-inline-start: 1rem;
  -webkit-padding-end: 1rem;
  padding-inline-end: 1rem;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  font-weight: 600;
  background: var(--cefacem-color);
  font-size: 14px;
}

a {
  color: var(--button-bg-color);
  text-decoration: none;
}

a:hover {
  color: var(--button-bg-color);
  text-decoration: underline;
}

.info-topic {
  border-radius: 70px;
  padding: 0.25rem 0rem;
  background: var(--cefacem-color-light);
  font-size: 14px;
}

.info-topic2 {
  border-radius: 10px;
  padding: 0.25rem 0rem;
  background: var(--cefacem-color-light);
  font-size: 14px;
}

.info-topic-section-white {
  border-radius: 9999px;
  -webkit-padding-start: 0.5rem;
  padding-inline-start: 0.5rem;
  -webkit-padding-end: 0.5rem;
  padding-inline-end: 0.5rem;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  font-weight: 600;
  background: var(--button-color);
  font-size: 14px;
}

.topic {
    line-height: 1.33;
    font-weight: 600;
    font-size: 20px;
    color: var(--button-bg-color);
}

.italic {
  font-style: italic;
}
.padding-top {
  padding-top: 20px;
}

.info-banner-label {
  white-space: pre-wrap;
  font-size: 12px;
  color: var(--grey-color);
  line-height: 1.2;
}

.image_wrapper {
  position: relative;
}

.overlay {
  position: absolute;
  /*background: rgba(57, 57, 57, 0.5);*/
  color: white;
  /* center overlay text */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 5;
}

.overlay_2 {
  inset: 0;
  display: none;
  pointer-events: none;
}

.image_wrapper:hover .overlay_2 {
  display: flex;
}

img.image_wrapper:hover {
  filter: brightness(0.40)!important;
}

.book-name {
  font-size: 2em!important;
  color: var(--black);
  text-align: start;
}

.black {
  color: var(--black);
}

.border.clickable:hover {
  cursor: pointer;
  filter: drop-shadow(rgba(205, 180, 219, 0.5) 0px 30px 30px);
}

.more-to-load-mask {
  -webkit-mask-image: linear-gradient(black, transparent);
  mask-image: linear-gradient(black, transparent);
}

.hidden {
  display: none;
}

.plan-dot {
  position: absolute;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  display: inline-block;
  z-index: 2;
  top: 5px;
  right: 5px;
}

img.bookInPlan {
  max-height: 190px;
  max-width: 190px;
  object-fit: cover;
}

.modal-body-scrollable {
  max-height: 60vh; /* Adjust the height as needed */
  overflow-y: auto;
}
.no-wrap {
  flex-wrap: nowrap!important;
}

.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2em;
  z-index: 1000;
}

.responsive-flex {
  display: flex;
  flex-direction: column;
}

@media (min-width: 650px) {
  .responsive-flex {
    flex-direction: row;
  }
}
